



















import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    InsHeader: () => import('@/components/business/header/InsHeader.vue'),
    PcHeader: () => import('@/components/business/header/layout/PcHeader.vue'),
    InsFooter: () => import('@/components/business/footer/InsFooter.vue'),
    InsSlideMenu: () => import('@/components/business/header/InsSlideMenu.vue'),
    InsSidebar: () => import('@/components/business/header/InsSidebar.vue'),
    InsMenuLayout: () => import('@/components/business/header/InsMenuLayout.vue')
  }
})
export default class layout extends Vue {
  get isMobile () {
    return this.$store.state.isMobile;
  }
}
